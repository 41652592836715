body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** for SVG line animation */
@keyframes draw {
  to {
      stroke-dashoffset: 0;
  }
}

@keyframes flow {
  to {
      stroke-dashoffset: 0;
  }
}

@keyframes dash {
  to {
      stroke-dashoffset: 0;
  }
}

@keyframes dashReverse {
  to {
      stroke-dashoffset: 5;
  }
}

@keyframes influence {
  to {
      stroke-dashoffset: 0;
  }
}